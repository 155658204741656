export const region = "ap-northeast-1";
export const stage = "staging";
export const bkpPath = "https://edge.bitkey.tokyo/v1";
export const ifBaseApiPath =
  "https://staging.api.cde.og.bitlock.xyz/link/api/v1";
// export const ifBaseApiPath = 'http://0.0.0.0:3003/api/v1';
export const bitlockApiPath =
  "https://us-central1-bitlock-staging.cloudfunctions.net/ext-v1";
export const bitlockVoApiPath =
  "https://us-central1-bitlock-staging.cloudfunctions.net/ext-vo";
export const bitlockVoApiKey = `xkoejfkwjkdhsfjkh7348979285-2nklnfklnfklfl.ekkjnfgknjkn.mvffl`;
export const bitlockVoVerifyIdentityApiKey = `dhfhsdhfuodsghfhflh782942oyvuhnvakkqkdkhvbfrwrywv4756295viu24y587`;

// サイトでの基本的な動的変数の定義
export const basicSiteVariablesDefinition = {
  projectId: '7a36af26-34c3-4ef7-9920-f876cde2bc44',
  linkageDateFormat: 'YYYY-MM-DD'
};